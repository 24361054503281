<template>
    <div style="font-size: 14px;padding-bottom: 60px;height: 100%;" class="history_div">
        <van-tabs v-model="common.historyIndex" @click="onClick">
            <van-tab title="点评">
                <history-explore-dialog></history-explore-dialog>
            </van-tab>
            <van-tab title="霸王餐">
                <history-lottery-dialog></history-lottery-dialog>
            </van-tab>
        </van-tabs>
        <shop-tabbar-dialog :active="1" @navigate="navigate"></shop-tabbar-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import HistoryExploreDialog from "./Dialog/HistoryExploreDialog";
    import HistoryLotteryDialog from "./Dialog/HistoryLotteryDialog";
    import ShopTabbarDialog from "./Dialog/ShopTabbarDialog";
    export default {
        name: "History",
        components:{
            HistoryExploreDialog,
            HistoryLotteryDialog,
            ShopTabbarDialog,
        },
        computed:{
            ...mapState(['common']),
        },
        data(){
            return {
                active: 1,
            }
        },
        mounted() {
            if(this.$route.query.tab === undefined) return;
            this.common.historyIndex = parseInt(this.$route.query.tab);
        },
        methods:{
            navigate(page){
                this.$router.replace(page);
            },
            onClick(index) {
                this.common.historyIndex = index;
            },
        }
    }
</script>

<style scoped>

</style>
