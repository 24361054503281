<template>
    <div>
        <van-search v-model="value0" placeholder="请输入中奖码或达人昵称" @search="onRefresh"/>
        <van-dropdown-menu class="dropdown_menu">
            <van-dropdown-item v-model="value1" :options="option1" text="shop_name" value="shop_id"/>
            <van-dropdown-item v-model="value2" :options="option2" text="goods_name" value="goods_id"/>
            <van-dropdown-item v-model="value3" :options="option3"/>
        </van-dropdown-menu>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadData" offset="100">
                <van-collapse v-model="activeName" accordion>
                    <van-collapse-item v-for="(item,index) in list" :key="index" :name="index">
                        <div slot="title" class="coll_title">
                            <p>
                                <span>
                                    <span style="font-weight: bold;">{{item.goods_name}}</span>
                                </span>
                                <span v-if="item.status === 0" style="color: #969799;float: right;margin-right: 10px;">{{item.status_name}}</span>
                                <span v-if="item.status === 1" style="color: #969799;float: right;margin-right: 10px;">{{item.status_name}}</span>
                                <span v-if="item.status === 2" style="color: #ee0a24;float: right;margin-right: 10px;">{{item.status_name}}</span>
                                <span v-if="item.status === 3" style="color: #07c160;float: right;margin-right: 10px;">{{item.status_name}}</span>
                                <span v-if="item.status === 4" style="color: #969799;float: right;margin-right: 10px;">{{item.status_name}}</span>
                            </p>
                            <p v-if="item.status === 2">
                                <span style="color: #aaa;">达人昵称：{{item.account_name}}</span>
                            </p>
                            <p v-if="item.status === 2">
                                <span style="color: #aaa;">中奖号码：{{item.code}}</span>
                            </p>
                        </div>
                        <div class="coll_detail" slot="default">
                            <p>活动：{{item.goods_name}}</p>
                            <p>价格：￥{{item.price}}</p>
                            <p>店铺：{{item.shop_name}}</p>
                            <p>发布时间：{{item.start_time}}</p>
                            <p v-if="item.draw_time">开奖时间：{{item.draw_time}}</p>
                            <p v-if="item.canceled_at">取消时间：{{item.canceled_at}}</p>
                            <p v-if="item.remark">取消原因：{{item.remark}}</p>
                            <p v-if="item.consumed_at">核销时间：{{item.consumed_at}}</p>
                        </div>
                        <div class="work_box">
                            <van-cell-group>
                                <van-cell title="中奖号码" v-if="item.status === 2 || item.status === 3" :value="item.code"></van-cell>
                                <van-cell title="达人昵称" v-if="item.status === 2 || item.status === 3" :value="item.account_name" is-link clickable @click="getImg(item.verify_img)"></van-cell>
                                <van-cell title="联系电话" v-if="item.status === 2 || item.status === 3">
                                    <a :href="'tel:'+ item.phone">{{item.phone}}</a>
                                </van-cell>
                            </van-cell-group>
                            <p v-if="item.status === 2" style="text-align: right;padding-right: 12px;">
                                <van-button size="small" round style="padding: 0 20px;margin-right: 5px;" @click="getImg(item.collection_qr_code)">查看收款码</van-button>
                                <van-button size="small" round color="linear-gradient(to right,#ff6034,#ee0a24)" style="padding: 0 20px;" @click="consume(item.act_id)">确认完成</van-button>
                            </p>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {Dialog, Toast, Notify, ImagePreview} from "vant";

    export default {
        name: "HistoryLotteryDialog",
        data() {
            return {
                activeName: '',
                loading: false,
                pageSize: 10,
                pageNo: 1,
                list: [],
                finished: false,
                refreshing: false,

                value0: '',
                value1: 0,
                value2: 0,
                value3: -1,
                option1: [
                    {text: '所有店铺', value: 0},
                ],
                option2: [
                    {text: '所有活动', value: 0},
                    {text: '请先选择店铺', value: -1},
                ],
                option3: [
                    {text: '全部状态', value: -1},
                    {text: '进行中', value: 0},
                    {text: '正在开奖', value: 1},
                    {text: '已开奖', value: 2},
                    {text: '已结束', value: 3},
                    {text: '已关闭', value: 4},
                ],

                inited: false,
            }
        },
        watch: {
            value1(newValue) {
                if (!this.inited) return;
                this.onRefresh();
                for (let i = 0; i < this.option1.length; i++) {
                    if (newValue === this.option1[i].value) {
                        this.option2 = this.deepCopy(this.option1[i].goods);
                        break;
                    }
                }
                this.value2 = 0;
                this.option2.unshift({text: '所有活动', value: 0});
            },
            value2() {
                if (!this.inited) return;
                this.onRefresh();
            },
            value3() {
                if (!this.inited) return;
                this.onRefresh();
            },
        },
        mounted() {
            // 查询所有存在霸王餐记录的店铺
            this.post('/api/client/common/history/lottery/before').then(result => {
                if (result.code === 0) {
                    this.option1 = result.data;
                    this.option1.unshift({text: '所有店铺', value: 0, goods: []});
                    this.inited = true;
                } else {
                    Toast('服务异常，请稍后再试');
                }
            });
        },
        methods: {
            loadData() {
                let that = this;
                this.post('/api/client/common/history/lottery', {
                    code: that.value0,
                    shop_id: that.value1,
                    goods_id: that.value2,
                    status: that.value3,
                    pageSize: that.pageSize,
                    pageNo: that.pageNo
                }).then(result => {
                    if (result.code === 0) {

                        // 刷新
                        if (this.refreshing) {
                            this.list = [];
                            this.refreshing = false;
                        }
                        for (let i = 0; i < result.data.length; i++) {
                            this.list.push(result.data[i]);
                        }
                        that.pageNo = that.pageNo + 1;
                        this.finished = result.data.length < this.pageSize;
                        this.loading = false;
                    } else {
                        Toast(result.msg);
                    }
                    this.loadSuccess = true;
                });
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.refreshing = true;
                this.loading = true;
                this.pageNo = 1;
                this.loadData();
            },
            consume(act_id) {
                Dialog.confirm({
                    title: '请确认',
                    message: '点击确认后即代表达人已到店完成体验任务并完成点评',
                }).then(() => {
                    this.post('/api/client/activity/lottery/consume', {act_id: act_id}).then(result => {
                        if (result.code === 0) {
                            Notify({type: 'success', message: result.msg});
                            this.pageNo = 1;
                            this.list = [];
                            this.finished = false; // 自动加载一次
                        } else {
                            Dialog.alert({
                                title: '核销失败',
                                message: result.msg,
                            });
                        }
                    })
                })
            },
            getImg(image_url) {
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0,
                });
                this.post('/api/common/images', {images: [image_url]}).then(result => {
                    if (result.code === 0) {
                        ImagePreview(result.data);
                    } else {
                        Notify('图片加载失败，请稍后重试');
                    }
                    Toast.clear();
                }).catch(e => {
                    Toast.clear();
                    Notify('图片加载失败，请稍后重试');
                })
            },
        }
    }
</script>

<style scoped>
    .coll_title {
        padding: 0;
    }

    .coll_title p {
        margin: 2px;
    }

    .coll_detail {
        padding: 5px 16px;
    }

    .coll_detail p {
        margin: 0;
    }

    .dropdown_menu > div:first-child {
        height: 40px;
    }
    .work_box {
        margin: 0 10px 15px;
        border: 1px solid #f7f8fa;
        border-radius: 5px;
        background: #fff;
        padding: 0;
        box-shadow: 0 8px 12px #ebedf0;
    }
</style>