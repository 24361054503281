<template>
    <div>
        <van-search v-model="value0" placeholder="请输入店铺名称" @search="onRefresh({})"/>
        <van-dropdown-menu class="dropdown_menu">
            <van-dropdown-item v-model="value1" :options="option1" text="shop_name" value="shop_id"/>
            <van-dropdown-item v-model="value2" :options="option2" text="goods_name" value="goods_id"/>
            <van-dropdown-item v-model="value3" :options="option3"/>
        </van-dropdown-menu>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadData" offset="100">
                <van-collapse v-model="activeName" accordion>
                    <van-collapse-item v-for="(item,index) in list" :name="index" class="coll_item">
                        <div slot="title" class="coll_title">
                            <p>
                                <span style="font-weight: bold;">{{item.shop_name}}<van-tag plain style="font-weight: normal;margin-left: 5px;">{{item.channel_name}}</van-tag></span>
                                <span v-if="item.status === 0" style="color: #07c160;float: right;margin-right: 10px;">发单中</span>
                                <span v-if="item.status === 3" style="color: #969799;float: right;margin-right: 10px;">订单取消</span>
                                <span v-if="item.works.length && item.works[0].status === 0" style="color: #07c160;float: right;margin-right: 10px;">{{item.works[0].status_name}}</span>
                                <span v-if="item.works.length && item.works[0].status === 1" style="color: #ee0a24;float: right;margin-right: 10px;">{{item.works[0].status_name}}</span>
                                <span v-if="item.works.length && item.works[0].status === 2" style="color: #07c160;float: right;margin-right: 10px;">{{item.works[0].status_name}}</span>
                                <span v-if="item.works.length && item.works[0].status === 3" style="color: #07c160;float: right;margin-right: 10px;">{{item.works[0].status_name}}</span>
                                <span v-if="item.works.length && item.works[0].status === 4" style="color: #969799;float: right;margin-right: 10px;">{{item.works[0].status_name}}</span>
                                <span v-if="item.works.length && item.works[0].status === 5" style="color: #07c160;float: right;margin-right: 10px;">{{item.works[0].status_name}}</span>
                                <span v-if="item.works.length && item.works[0].status === 6" style="color: #969799;float: right;margin-right: 10px;">工单取消</span>
                            </p>
                            <p>
<!--                                发单过期-->
                                <span v-if="item.status === 0 && item.canceled_at" style="color: #ee0a24;">
                                    <van-count-down style="display: inline;color: #ee0a24;margin-right: 5px;"
                                                    :time="item.canceled_at * 1000"
                                                    @finish="onRefresh({eo_id:item.eo_id})">
                                        <template v-slot="timeData">
                                            <span v-if="timeData.days > 0">{{timeData.days}}天&nbsp;</span>
                                            {{timeData.hours>9?timeData.hours:'0'+timeData.hours}}:{{timeData.minutes>9?timeData.minutes:'0'+timeData.minutes}}:{{timeData.seconds>9?timeData.seconds:'0'+timeData.seconds}}
                                            后发单过期
                                        </template>
                                    </van-count-down>
                                </span>
<!--                                投稿超时-->
                                <span v-if="item.works.length && item.works[0].canceled_at" style="color: #aaa;">
                                    <van-count-down style="display: inline;color: #aaa;margin-right: 5px;"
                                                    :time="item.works[0].canceled_at * 1000"
                                                    @finish="onRefresh({overtime:true})" >
                                        <template v-slot="timeData">
                                            <span v-if="timeData.days > 0">{{timeData.days}}天&nbsp;</span>
                                            {{timeData.hours>9?timeData.hours:'0'+timeData.hours}}:{{timeData.minutes>9?timeData.minutes:'0'+timeData.minutes}}:{{timeData.seconds>9?timeData.seconds:'0'+timeData.seconds}}
                                                后投稿超时
                                        </template>
                                    </van-count-down>
                                </span>
<!--                                自动验收-->
                                <span v-if="item.works.length && item.works[0].passed_at" style="color: #ee0a24;">
                                    <van-count-down style="display: inline;color: #ee0a24;margin-right: 5px;"
                                                    format="HH:mm:ss 后自动验收"
                                                    :time="item.works[0].passed_at * 1000"
                                                    @finish="onRefresh({pass:true})" />
                                </span>
                            </p>
                        </div>
                        <div class="coll_detail" slot="default">
                            <p>探店时间：{{item.start_date}}{{item.start_date === item.end_date?'':'~'+item.end_date}}
                                <van-icon name="photo" size="30" style="float: right;" @click="orderImage(item, index)"
                                          v-if="item.shop_img.length"/>
                                <van-button size="mini" round style="float: right;padding:0 6px;margin: 5px;"
                                            v-if="item.cancelable" @click="cancelOrder(item.eo_id)">
                                    取消订单
                                </van-button>
                            </p>
                            <p>发单时间：{{item.created_at}}</p>
                            <p @click="copy(item.remark)" v-if="item.remark">发单备注：{{item.remark}}</p>
                        </div>
                        <!--无人接单-->
                        <van-divider v-if=" item.works.length === 0">无人接单</van-divider>
                        <!--加载完成-->
                        <div class="work_box" v-for="work in item.works">
                            <van-steps :hidden="!work.step.steps.length" :active="work.step.current_step"
                                       :active-color="work.step.err_msg?'rgb(238, 10, 36)':'#07c160'"
                                       :active-icon="work.step.err_msg?'clear':'checked'">
                                <van-step v-for="st in work.step.steps">{{st}}</van-step>
                            </van-steps>
                            <van-cell-group>
                                <van-cell v-if="work.step.steps.length && work.step.err_msg" title="待处理"
                                          :value="work.step.err_msg" is-link @click="showPopup(work.work_id)"></van-cell>
                                <van-cell v-if="!work.step.steps.length && work.step.err_msg" title="已取消"
                                          :value="work.step.err_msg" is-link @click="showPopup(work.work_id)"></van-cell>
                                <van-cell title="工单编号" :value="work.work_no"></van-cell>
                                <!--                        <van-cell title="联系电话" :value="work.phone"></van-cell>-->
                                <van-cell title="达人账号" :value="work.talent_name" is-link @click="talentImage(work,index)"></van-cell>
                                <van-cell title="接单时间" :value="work.receive_time"></van-cell>
                                <van-cell title="投稿时间" v-if="work.submit_time" :value="work.submit_time"></van-cell>
                                <van-cell title="验收时间" v-if="work.accept_time" :value="work.accept_time"></van-cell>
                                <van-cell title="验收备注" v-if="work.accept_remark" :value="work.accept_remark"></van-cell>
                                <van-cell title="结算时间" v-if="work.settlement_time" :value="work.settlement_time"></van-cell>
                                <van-cell title="投稿截图" v-if="work.submit_img" value="点击查看" is-link
                                          @click="imagePreview(work.submit_img)"></van-cell>
                                <van-cell title="投稿链接" v-if="work.submit_remark" :value="work.submit_remark" @click="copy(work.submit_remark)"></van-cell>
                                <van-cell title="审核操作" v-if="work.status === 1">
                                    <van-button size="small" round color="linear-gradient(to right,#ff6034,#ee0a24)" style="padding: 0 20px;" @click="verify(work.work_id)">验收</van-button>
                                </van-cell>
                            </van-cell-group>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </van-list>
        </van-pull-refresh>
        <van-dialog v-model="show" title="探店详情" closeOnClickOverlay>
            <div style="max-height: 400px;overflow: auto;">
                <van-steps direction="vertical" :active="0" class="popup_step">
                    <van-step v-for="item in logs">
                        <p>{{item.remark}}</p>
                        <p>{{item.created_at}}</p>
                    </van-step>
                </van-steps>
            </div>
        </van-dialog>
        <van-dialog v-model="confirmShow" title="验收" show-cancel-button :before-close="confirm">
            <van-cell-group>
                <van-field label="是否通过">
                    <template #input>
                        <van-radio-group v-model="accept" direction="horizontal">
                            <van-radio :name="1">通过</van-radio>
                            <van-radio :name="0">不通过</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field
                        v-model="accept_remark"
                        rows="5"
                        autosize
                        label="回复内容"
                        type="textarea"
                        placeholder="请输入给达人的回复内容"
                />
            </van-cell-group>
        </van-dialog>
    </div>
</template>

<script>
    import {Dialog, ImagePreview, Notify, Toast} from "vant";

    export default {
        name: "HistoryExploreDialog",
        data() {
            return {
                activeName: '',
                list: [],
                show: false,
                logs: [],
                pageSize: 20,
                pageNo: 1,
                loading: false,
                finished: false,
                first: false,
                refreshing: false,

                confirmShow: false,
                work_id: null,
                accept: '',
                accept_remark: '',

                value0: '',
                value1: 0,
                value2: 0,
                value3: -1,
                option1: [
                    {text: '所有店铺', value: 0},
                ],
                option2: [
                    {text: '接单时间', value: 0},
                    {text: '全部', value: 0},
                    {text: '最近7天', value: 7},
                    {text: '最近30天', value: 30},
                    {text: '最近90天', value: 90},
                ],
                option3: [
                    {text: '全部状态', value: -1},
                    {text: '发单中', value: 0},
                    {text: '已接单', value: 1},
                    {text: '已投稿', value: 2},
                    {text: '已验收', value: 3},
                    {text: '待结算', value: 4},
                    {text: '已结算', value: 5},
                    {text: '验收不通过', value: 6},
                    {text: '已取消', value: 7},
                ],
                inited: false,
            }
        },
        watch: {
            value1() {
                if (!this.inited) return;
                this.onRefresh();
            },
            value2() {
                if (!this.inited) return;
                this.onRefresh();
            },
            value3() {
                if (!this.inited) return;
                this.onRefresh();
            },
        },
        mounted() {
            // 查询所有存在霸王餐记录的店铺
            this.post('/api/client/common/history/explore/before').then(result => {
                if (result.code === 0) {
                    this.option1 = result.data;
                    this.option1.unshift({text: '所有店铺', value: 0, goods: []});
                    this.inited = true;
                } else {
                    Toast('服务异常，请稍后再试');
                }
            });
        },
        methods: {
            copy(message) {
                this.$copyText(message).then(res => {
                    Toast('已复制到剪贴板');
                }).catch(err => {
                    alert('Can not copy');
                })
            },
            loadData(params = {}) {
                let that = this;
                console.log(params);
                Object.assign(params,{
                    pageSize: that.pageSize,
                    pageNo: that.pageNo,
                    shop_name: that.value0,
                    shop_id: that.value1,
                    time: that.value2,
                    status: that.value3,
                })
                this.post('/api/client/common/history/explore', params).then(result => {
                    if (result.code === 0) {
                        // 刷新
                        if (this.refreshing) {
                            this.list = [];
                            this.refreshing = false;
                        }
                        for (let i = 0; i < result.data.length; i++) {
                            this.list.push(result.data[i]);
                        }
                        that.pageNo = that.pageNo + 1;
                        this.finished = result.data.length < this.pageSize;
                        this.loading = false;
                    } else {
                        Toast(result.msg);
                    }
                });
            },
            onRefresh(params = {}) {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.refreshing = true;
                this.loading = true;
                this.pageNo = 1;
                this.loadData(params);
            },
            imagePreview(url) {
                ImagePreview({
                    images: [url],
                    closeable: true,
                    closeOnPopstate: true,
                });
            },
            showPopup(work_id) {
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0,
                });
                this.post('/api/client/talent/history/log', {work_id: work_id}).then(result => {
                    if (result.code === 0) {
                        this.logs = result.data;
                        this.show = true;
                    } else {
                        Notify(result.msg);
                    }
                    Toast.clear();
                })
            },
            // 查看订单图片
            orderImage(item, index) {
                console.log(item.shop_img);
                console.log(item.imgs);
                console.log(index);
                let that = this;
                if (item.imgs.length) {
                    ImagePreview(item.imgs);
                } else {
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration: 0,
                    });
                    this.post('/api/common/images', {images: item.shop_img}).then(result => {
                        if (result.code === 0) {
                            that.list[index].imgs = result.data;
                            ImagePreview(result.data);
                        } else {
                            Notify('图片加载失败，请稍后重试');
                        }
                        Toast.clear();
                    }).catch(e => {
                        Toast.clear();
                        Notify('图片加载失败，请稍后重试');
                    })
                }
            },
            talentImage(work,index){
                let that = this;
                if (work.img_url) {
                    ImagePreview(work.img_url);
                } else {
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration: 0,
                    });
                    this.post('/api/common/images', {images: [work.verify_img]}).then(result => {
                        if (result.code === 0) {
                            that.list[index].works[0].img_url = result.data;
                            ImagePreview(result.data);
                        } else {
                            Notify('图片加载失败，请稍后重试');
                        }
                        Toast.clear();
                    }).catch(e => {
                        Toast.clear();
                        console.log(e);
                        Notify('图片加载失败，请稍后重试');
                    })
                }
            },
            cancelOrder(eo_id) {
                console.log(eo_id);
                Dialog.confirm({
                    title: '请确认',
                    message: '确定取消此订单？',
                }).then(() => {
                    this.post('/api/client/shop/explore/cancel', {eo_id: eo_id}).then(result => {
                        if (result.code === 0) {
                            Notify({type: 'success', message: result.msg});
                            // 清空list，重新加载
                            this.onRefresh();
                        } else {
                            Dialog.alert({
                                title: '取消失败 ',
                                message: result.msg,
                            });
                        }
                    });
                });
            },
            verify(work_id){
                this.work_id = work_id;
                this.confirmShow = true;
            },
            confirm(action,done) {
                let that = this;
                if(action !== 'confirm'){
                    done();
                    return;
                }
                let accept = this.accept;
                let accept_remark = this.accept_remark;
                if(accept === ''){
                    Toast('请选择是否验证通过');
                    done(false);
                    return;
                }
                if(!accept_remark){
                    Toast('请输入展示给达人的回复内容');
                    done(false);
                    return;
                }
                Dialog.confirm({
                    title: '请确认',
                    message: accept?'您检查并已确认达人的投稿符合您的要求':'您将拒回达人的投稿，请确认达人的投稿内容不符合您的要求',
                }).then(() => {
                    that.post('/api/client/shop/explore/confirm', {work_id: that.work_id,accept:accept,accept_remark:accept_remark,}).then(result => {
                        if (result.code === 0) {
                            Toast.success(result.msg);
                            // 清空list，重新加载
                            this.onRefresh();
                            done();
                        } else {
                            done(false);
                            Dialog.alert({
                                title: '操作失败',
                                message: result.msg,
                            });
                        }
                    }).catch(e=>{
                        Toast('服务器错误');
                        done(false);
                    });
                }).catch(()=>{
                    done();
                })
            },
        }
    }
</script>

<style scoped>
    .history_div {
    }

    .coll_title {
        padding: 0;
    }

    .coll_title p {
        margin: 2px;
    }

    .coll_detail {
        padding: 5px 16px;
    }

    .coll_detail p {
        margin: 0;
    }

    .work_box {
        margin: 0 10px 15px;
        border: 1px solid #f7f8fa;
        border-radius: 5px;
        background: #fff;
        padding: 0;
        box-shadow: 0 8px 12px #ebedf0;
    }
</style>
<style>
    .history_div .van-collapse-item__content {
        padding: 0;
        background: #f7f8fa;
    }

    .popup_step p {
        margin: 2px;
    }
    .coll_item>div:first-child{
        padding: 10px 16px;
    }
</style>
